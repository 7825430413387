import { Button } from "components/Button";
import { Heading } from "components/Heading";
import { Container } from "components/Layout/Container";
import { ApplicationPageContainer } from "./ApplicationPageContainer";
import { HeadingLevel } from "types/HeadingLevel";

export const ApplicationNotAllowedPage = () => (
    <ApplicationPageContainer>
        <Container className="px-0 pt-5 align-middle text-center" style={{ paddingBottom: "6rem" }}>
            <Heading level={HeadingLevel.H1} className="fw-bold py-5 overflow-hidden" style={{ fontSize: "3.5rem" }}>
                Thank you for your interest
            </Heading>
            <p className="fs-6 p-2 text-wrap">New applications are not being accepted at this time.</p>
            <Button className="p-2 w-100" style={{ maxWidth: "23rem" }} href="/" target="_self" variant="primary" text="Go to homepage" />
        </Container>
    </ApplicationPageContainer>
);
